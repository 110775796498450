// supported search settings; we'll bail if other settings are supplied
const SEARCHOPTIONS_FREE_SEARCH = JSON.stringify({
  matchCase: false,
  matchWholeWord: false,
})

const SEARCHOPTIONS_WORD_BOUNDERY = JSON.stringify({
  matchCase: false,
  matchWholeWord: true,
})

/**
 * Add-in internal representation of the Word document. Used to provide functionality
 * not offered by Word itself (such as contexts of search results).
 */
export default class ShadowDocument {
  bodyText: string

  // prevent direct creation of (incomplete) instances
  private constructor(bodyText: string) {
    this.bodyText = bodyText
  }

  static async newInstance(): Promise<ShadowDocument> {
    const text = await Word.run(async (context) => {
      const { body } = context.document
      body.load('text')
      await context.sync()
      return body.text
    })
    return new ShadowDocument(text)
  }

  private static escapeRegExp(pattern: string) {
    return pattern.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')
  }

  private static buildRegExp(
    pattern: string,
    searchOptions: Record<string, Boolean>
  ) {
    // for now, we support only one specific search configuration. error out
    // if something else is being requested
    const s = JSON.stringify(searchOptions)
    try {
      if (s === SEARCHOPTIONS_FREE_SEARCH)
        return new RegExp(
          // the capture groups delimiting the actual pattern are used to
          // make the position of the pattern correspond to that in
          // SEARCHOPTIONS_WORD_BOUNDERY: its the second capture group with
          // the first representing an (possibly empty) prefix
          `(.|^)(${ShadowDocument.escapeRegExp(pattern)})(.|$)`,
          'uigs'
        )

      if (s === SEARCHOPTIONS_WORD_BOUNDERY)
        return new RegExp(
          // the pattern simulates the word-boundary behavior of the Word
          // search
          `(\\W|^)(${ShadowDocument.escapeRegExp(pattern)})(\\W|$)`,
          'uig'
        )
    } catch (ex) {
      if (s === SEARCHOPTIONS_FREE_SEARCH)
        return new RegExp(
          // the capture groups delimiting the actual pattern are used to
          // make the position of the pattern correspond to that in
          // SEARCHOPTIONS_WORD_BOUNDERY: its the second capture group with
          // the first representing an (possibly empty) prefix
          `(.|^)(${ShadowDocument.escapeRegExp(pattern)})(.|$)`,
          'ig'
        )

      if (s === SEARCHOPTIONS_WORD_BOUNDERY)
        return new RegExp(
          // the pattern simulates the word-boundary behavior of the Word
          // search
          `(\\W|^)(${ShadowDocument.escapeRegExp(pattern)})(\\W|$)`,
          'ig'
        )
    }
    throw new Error('unsupported search options supplied')
  }

  /**
   * Returns the next position of a match of the supplied pattern string.
   *
   * @param searchSettings the search configuration to be used. See
   *  https://docs.microsoft.com/en-us/office/dev/add-ins/word/search-option-guidance.
   * @param pattern the text to search for
   * @param startPos: the start character index in the document body
   *
   * @return the match position as an offset to the supplied start position, or
   *  a negative value if the pattern is not found
   */
  findNextMatchPosition(
    searchSettings: Record<string, Boolean>,
    pattern: string,
    startPos: number = 0
  ) {
    return this.bodyText
      .substring(startPos)
      .search(ShadowDocument.buildRegExp(pattern, searchSettings))
  }

  /**
   * Returns the context of the next match of the supplied pattern.
   *
   * @param searchSettings the search configuration to be used. See
   *  https://docs.microsoft.com/en-us/office/dev/add-ins/word/search-option-guidance.
   * @param pattern the text to search for
   * @param startPos the start character index in the document body
   * @param contextSize maximum size of the context in chars
   *
   * @return object representing the match context, or undefined if no match
   *  is found
   */
  findNextMatchContext(
    searchSettings: Record<string, Boolean>,
    pattern: string,
    startPos: number = 0,
    contextSize: number = 16
  ) {
    const regexp = ShadowDocument.buildRegExp(pattern, searchSettings)
    const matches = regexp.exec(this.bodyText.substring(startPos))
    if (!matches) return undefined

    // remember to take the prefix group matches[1] into account!
    const mpos = matches.index + matches[1].length
    if (mpos < 0) return undefined

    const matchStartPos = startPos + mpos
    const contextStart = Math.max(matchStartPos - contextSize, 0)

    const matchEndPos = startPos + mpos + pattern.length
    const contextEndPos = Math.min(
      matchEndPos + contextSize,
      this.bodyText.length
    )

    return {
      matchStartPos,
      beforeMatch: this.bodyText.substring(contextStart, matchStartPos),
      afterMatch: this.bodyText.substring(matchEndPos, contextEndPos),
    }
  }
}
