import { MsgBarDefinition } from './msgBarsState'

export const SHOW_MSG_BAR = 'SHOW_MSG_BAR'
export const DISMISS_MSG_BAR = 'DISMISS_MSG_BAR'

interface ShowMsgBarAction {
  type: typeof SHOW_MSG_BAR
  msgBar: MsgBarDefinition
}

interface DismissMsgBarAction {
  type: typeof DISMISS_MSG_BAR
  msgBar: MsgBarDefinition
}

export type ActionTypes = ShowMsgBarAction | DismissMsgBarAction
