import React, { Fragment } from 'react'
import {
  Checkbox,
  DefaultButton,
  IStackTokens,
  Spinner,
  SpinnerSize,
  Stack,
  Text,
} from '@fluentui/react'
import { useDispatch, useSelector } from 'react-redux'
import TokenManager from '@xpect-ai/ms-graph-api-token-manager'
import * as Actions from './authActions'
import { isAuthenticated } from './authSelectors'

const stackProperties: IStackTokens = { childrenGap: 20 }

export const AuthSplash: React.FunctionComponent<any> = (props) => {
  const dispatch = useDispatch()
  const [isLoading, setLoading] = React.useState(false)
  const isAuth = useSelector(isAuthenticated)

  const setAutoAuth = (
    _ev?: React.FormEvent<HTMLElement | HTMLInputElement>,
    isChecked?: boolean
  ) => {
    localStorage.setItem('auto-auth', isChecked ? 'on' : 'off')
  }

  const tryToAuthenticate = async () => {
    setLoading(true)
    const token = await TokenManager.getInstance().getToken()
    setLoading(false)
    const success = !!token
    if (!success) {
      setAutoAuth(undefined, false)
    }
    dispatch({ type: Actions.SET_AUTH_STATE, authenticated: success })
  }

  React.useEffect(() => {
    if (localStorage.getItem('auto-auth') === 'on') {
      tryToAuthenticate()
    }
  }, [])

  return isAuth ? (
    props.children
  ) : (
    <div className="content-container">
      <h2>Authenticate the Add-In</h2>
      <Stack tokens={stackProperties}>
        <Text>
          This add-in allows you to easily find personal information and
          instantly replace it with another user’s data.
        </Text>
        <Text>
          The search and replace functionality utilizes data from your
          organization's active directory. In order to enable the add-in to
          access this data, you have to authorize the add-in by clicking below:
        </Text>
        {isLoading ? (
          <Spinner label="Authenticating..." size={SpinnerSize.large} />
        ) : (
          <Fragment>
            <DefaultButton
              id="ui-button-authenticate"
              text="Authenticate"
              onClick={tryToAuthenticate}
            />
            <div>
              <Checkbox label="Don't ask again" onChange={setAutoAuth} />
            </div>
          </Fragment>
        )}
        <Text>
          For further details, please see our{' '}
          <a
            href="#"
            onClick={() => window.open('https://xpect.ai/privacy.html')}
          >
            Privacy Policy
          </a>{' '}
          and{' '}
          <a
            href="#"
            onClick={() => window.open('https://xpect.ai/terms.html')}
          >
            Terms of Service
          </a>
          .
        </Text>
      </Stack>
    </div>
  )
}
