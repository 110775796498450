import { RootState } from '../../app/state'
import { IEntity } from '../interfaces/interfaces'

export const getSelection =
  (role: string) =>
  (state: RootState): IEntity[] =>
    state.selection.selection.get(role) || []

export const getMostRecentlyUsed = (state: RootState): IEntity[] =>
  state.selection.mostRecentlyUsed
