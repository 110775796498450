import TestCore from '@xpect-ai/e2e-test-framework-react'
import { WordUtils } from '@xpect-ai/ms-office-utils'
import testContent from '../e2etests/testDocumentForE2ETest'

function getCurrentSelection(): Promise<string | undefined> {
  return new Promise<string>((selection, error) => {
    try {
      Office.context.document.getSelectedDataAsync(
        Office.CoercionType.Text,
        (selectedText: any) => {
          if (selectedText.status === Office.AsyncResultStatus.Failed) {
            error(selectedText.error)
          } else {
            selection(selectedText.value)
          }
        }
      )
    } catch (exc) {
      error(exc)
    }
  })
}

const Scenario1 = {
  name: 'App Scenario 1',
  steps: [
    {
      name: 'Clear the document',
      callDelegate: async () => {
        WordUtils.ClearDocument()
      },
    },
    {
      name: 'Insert test document',
      callDelegate: async () => {
        WordUtils.OverwriteDocumentWithOoxmlContent(testContent)
      },
    },
    {
      name: 'Insert search term into search box',
      callDelegate: async (testContext: any) => {
        /* Step 1 */
        await testContext.this.InsertValueIntoInputField(
          'combobox-id__10',
          'Adele',
          true
        )
      },
    },
    {
      name: 'Insert search term into search box (2nd) in case of login needed',
      callDelegate: async (testContext: any) => {
        await TestCore.delay(4000)
        /* Step 1 */
        await testContext.this.InsertValueIntoInputField(
          'combobox-id__10',
          'Adele',
          true
        )
      },
    },
    {
      name: 'Select found entity to search for matches inside the document',
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      callDelegate: async (testContext: any) => {
        /* Step 2 */
        await testContext.this.ClickButton('sug-0')
      },
    },
    {
      name: 'Find the matches',
      callDelegate: async (testContext: any) => {
        /* Step 3 */
        const expectedNumberOfEntries = 9
        // eslint-disable-next-line no-param-reassign
        testContext.searchResultList =
          await testContext.this.GetAllListEntriesAndWaitUntilComplete(
            'search-result-ui-list',
            expectedNumberOfEntries
          )
        TestCore.testComment('Found search list')
        testContext.this.ExpectStringToBe(
          `${testContext.searchResultList.length}`,
          `${expectedNumberOfEntries}`,
          `Not the expected number of search results ${testContext.searchResultList.length}`
        )
        TestCore.testComment('Found search results')
      },
    },
    {
      name: 'Select each match',
      callDelegate: async (testContext: any) => {
        /* Step 4 */
        for (
          let fCounter = 0;
          fCounter < testContext.searchResultList.length;
          fCounter += 1
        ) {
          const child = testContext.searchResultList[fCounter]
            .children[0] as HTMLButtonElement
          child.click()
          // eslint-disable-next-line no-await-in-loop
          await TestCore.delay(1250)
          // eslint-disable-next-line no-await-in-loop
          const selectedValue = (await getCurrentSelection())?.trim()
          /* Fetching the value from the current UI element is specific for the current div hierarchie within a single list entry.
          The task is to fetch the bold printed area. Track this and change to better solution later.
          */
          const listEntryValue =
            child.children[0].children[0].children[1].textContent?.trim()
          testContext.this.ExpectStringToBe(selectedValue, listEntryValue)
          // eslint-disable-next-line no-await-in-loop
          await TestCore.delay(250)
        }
      },
    },
    {
      name: 'Insert search term into replace box',
      callDelegate: async (testContext: any) => {
        /* Step 1 */
        await testContext.this.InsertValueIntoInputField(
          'combobox-id__12',
          'Siciliani',
          true
        )
      },
    },
    {
      name: 'Select found entity to add the replace suggestions to the matches inside the document',
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      callDelegate: async (testContext: any) => {
        /* Step 2 */
        try {
          await TestCore.delay(500)
          await testContext.this.ClickButton('sug-0')
        } catch {
          /* Ignore if it fails */
          TestCore.testComment(
            'E2E Failure of Button not found will be ignored, since this simply means entity was already selected.'
          )
        }
      },
    },
    {
      name: 'Hit the replace all button',
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      callDelegate: async (testContext: any) => {
        /* Step 2 */
        await testContext.this.ClickButton('ui-button-replace-all-matches')
      },
    },
    {
      name: 'Clear search and replace box',
      callDelegate: async (testContext: any) => {
        /* Step 2 */
        await TestCore.delay(1000)
        let allElements = document.getElementsByClassName(
          'ms-PickerItem-removeButton'
        )
        await testContext.this.ClickButton(allElements[0].id)
        await TestCore.delay(1000)
        allElements = document.getElementsByClassName(
          'ms-PickerItem-removeButton'
        )
        await testContext.this.ClickButton(allElements[0].id)
      },
    },
    {
      name: 'Insert search term into search box',
      callDelegate: async (testContext: any) => {
        /* Step 1 */
        await testContext.this.InsertValueIntoInputField(
          'combobox-id__10',
          'Adele',
          true
        )
      },
    },
    {
      name: 'Select found entity to search for matches inside the document',
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      callDelegate: async (testContext: any) => {
        /* Step 2 */
        await testContext.this.ClickButton('sug-0')
      },
    },
    {
      name: 'Check if no matches',
      callDelegate: async (testContext: any) => {
        await TestCore.delay(4000)
        /* Step 3 */
        const expectedNumberOfEntries = 0
        // eslint-disable-next-line no-param-reassign
        testContext.searchResultList =
          await testContext.this.GetAllListEntriesFromVirtualizedList(
            'search-result-ui-list'
          )
        TestCore.testComment('Found search list')
        testContext.this.ExpectStringToBe(
          `${testContext.searchResultList.length}`,
          `${expectedNumberOfEntries}`,
          `Not the expected number of search results ${testContext.searchResultList.length}`
        )
        TestCore.testComment(
          `Found search results ${testContext.searchResultList.length}`
        )
      },
    },
    {
      name: 'Clear search',
      callDelegate: async (testContext: any) => {
        /* Step 2 */
        await TestCore.delay(1000)
        const allElements = document.getElementsByClassName(
          'ms-PickerItem-removeButton'
        )
        await testContext.this.ClickButton(allElements[0].id)
      },
    },
    {
      name: 'Clear the document',
      callDelegate: async () => {
        WordUtils.ClearDocument()
      },
    },
    {
      name: 'Insert test document',
      callDelegate: async () => {
        WordUtils.OverwriteDocumentWithOoxmlContent(testContent)
      },
    },
    {
      name: 'Insert search term into search box',
      callDelegate: async (testContext: any) => {
        /* Step 1 */
        await testContext.this.InsertValueIntoInputField(
          'combobox-id__10',
          'Adele',
          true
        )
      },
    },
    {
      name: 'Select found entity to search for matches inside the document',
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      callDelegate: async (testContext: any) => {
        /* Step 2 */
        await testContext.this.ClickButton('sug-0')
      },
    },
    {
      name: 'Insert search term into replace box',
      callDelegate: async (testContext: any) => {
        /* Step 1 */
        await testContext.this.InsertValueIntoInputField(
          'combobox-id__12',
          'Siciliani',
          true
        )
      },
    },
    {
      name: 'Select found entity to add the replace suggestions to the matches inside the document',
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      callDelegate: async (testContext: any) => {
        /* Step 2 */
        await testContext.this.ClickButton('sug-0')
      },
    },
    {
      name: 'Hit first replace command',
      callDelegate: async () => {
        /* Step 2 */
        await TestCore.delay(1000)
        const allReplaceInserts =
          document.getElementsByClassName('replaceCommand')
        ;(allReplaceInserts[0] as HTMLButtonElement).click()
        await TestCore.delay(1000)
        const contextualMenuDontChangeEntry = document.getElementsByClassName(
          'ms-ContextualMenu-itemText'
        )[1]
        ;(contextualMenuDontChangeEntry as HTMLButtonElement).click()
      },
    },
    {
      name: 'Hit the replace all button',
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      callDelegate: async (testContext: any) => {
        /* Step 2 */
        await testContext.this.ClickButton('ui-button-replace-all-matches')
      },
    },
    {
      name: 'Clear search and replace box',
      callDelegate: async (testContext: any) => {
        /* Step 2 */
        await TestCore.delay(1000)
        let allElements = document.getElementsByClassName(
          'ms-PickerItem-removeButton'
        )
        await testContext.this.ClickButton(allElements[0].id)
        await TestCore.delay(1000)
        allElements = document.getElementsByClassName(
          'ms-PickerItem-removeButton'
        )
        await testContext.this.ClickButton(allElements[0].id)
      },
    },
    {
      name: 'Insert search term into search box',
      callDelegate: async (testContext: any) => {
        /* Step 1 */
        await testContext.this.InsertValueIntoInputField(
          'combobox-id__10',
          'Adele',
          true
        )
      },
    },
    {
      name: 'Select found entity to search for matches inside the document',
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      callDelegate: async (testContext: any) => {
        /* Step 2 */
        await testContext.this.ClickButton('sug-0')
      },
    },
    {
      name: 'Check if one match',
      callDelegate: async (testContext: any) => {
        await TestCore.delay(4000)
        /* Step 3 */
        const expectedNumberOfEntries = 1
        // eslint-disable-next-line no-param-reassign
        testContext.searchResultList =
          await testContext.this.GetAllListEntriesAndWaitUntilComplete(
            'search-result-ui-list',
            expectedNumberOfEntries
          )
        TestCore.testComment('Found search list')
        testContext.this.ExpectStringToBe(
          `${testContext.searchResultList.length}`,
          `${expectedNumberOfEntries}`,
          `Not the expected number of search results ${testContext.searchResultList.length}`
        )
        TestCore.testComment('Found search results')
      },
    },
    {
      name: 'Clear search',
      callDelegate: async (testContext: any) => {
        /* Step 2 */
        await TestCore.delay(1000)
        const allElements = document.getElementsByClassName(
          'ms-PickerItem-removeButton'
        )
        await testContext.this.ClickButton(allElements[0].id)
      },
    },
    {
      name: 'Clear the document',
      callDelegate: async () => {
        WordUtils.ClearDocument()
      },
    },
    {
      name: 'Insert test document',
      callDelegate: async () => {
        WordUtils.OverwriteDocumentWithOoxmlContent(testContent)
      },
    },
    {
      name: 'Insert search term into search box',
      callDelegate: async (testContext: any) => {
        /* Step 1 */
        await testContext.this.InsertValueIntoInputField(
          'combobox-id__10',
          'Adele',
          true
        )
      },
    },
    {
      name: 'Select found entity to search for matches inside the document',
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      callDelegate: async (testContext: any) => {
        /* Step 2 */
        await testContext.this.ClickButton('sug-0')
      },
    },
    {
      name: 'Insert search term into replace box',
      callDelegate: async (testContext: any) => {
        /* Step 1 */
        await testContext.this.InsertValueIntoInputField(
          'combobox-id__12',
          'Siciliani',
          true
        )
      },
    },
    {
      name: 'Select found entity to add the replace suggestions to the matches inside the document',
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      callDelegate: async (testContext: any) => {
        /* Step 2 */
        await testContext.this.ClickButton('sug-0')
      },
    },
    {
      name: 'Hit third replace command',
      callDelegate: async () => {
        /* Step 2 */
        await TestCore.delay(1000)
        const allReplaceInserts =
          document.getElementsByClassName('replaceCommand')
        ;(allReplaceInserts[2] as HTMLButtonElement).click()
        await TestCore.delay(1000)
        const contextualMenuDontChangeEntry = document.getElementsByClassName(
          'ms-ContextualMenu-itemText'
        )[1]
        ;(contextualMenuDontChangeEntry as HTMLButtonElement).click()
      },
    },
    {
      name: 'Select the dialog option',
      callDelegate: async () => {
        /* Step 2 */
        await TestCore.delay(1000)
        const allElements = document.getElementsByClassName(
          'ms-MessageBar-actions'
        )
        ;(allElements[0].children[0] as HTMLButtonElement)?.click()
      },
    },
    {
      name: 'Hit the replace all button',
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      callDelegate: async (testContext: any) => {
        /* Step 2 */
        await testContext.this.ClickButton('ui-button-replace-all-matches')
      },
    },
    {
      name: 'Clear search and replace box',
      callDelegate: async (testContext: any) => {
        /* Step 2 */
        await TestCore.delay(1000)
        let allElements = document.getElementsByClassName(
          'ms-PickerItem-removeButton'
        )
        await testContext.this.ClickButton(allElements[0].id)
        await TestCore.delay(1000)
        allElements = document.getElementsByClassName(
          'ms-PickerItem-removeButton'
        )
        await testContext.this.ClickButton(allElements[0].id)
      },
    },
    {
      name: 'Insert search term into search box',
      callDelegate: async (testContext: any) => {
        /* Step 1 */
        await testContext.this.InsertValueIntoInputField(
          'combobox-id__10',
          'Adele',
          true
        )
      },
    },
    {
      name: 'Select found entity to search for matches inside the document',
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      callDelegate: async (testContext: any) => {
        /* Step 2 */
        await testContext.this.ClickButton('sug-0')
      },
    },
    {
      name: 'Check if three matches',
      callDelegate: async (testContext: any) => {
        await TestCore.delay(4000)
        /* Step 3 */
        const expectedNumberOfEntries = 3
        // eslint-disable-next-line no-param-reassign
        testContext.searchResultList =
          await testContext.this.GetAllListEntriesAndWaitUntilComplete(
            'search-result-ui-list',
            expectedNumberOfEntries
          )
        TestCore.testComment('Found search list')
        testContext.this.ExpectStringToBe(
          `${testContext.searchResultList.length}`,
          `${expectedNumberOfEntries}`,
          `Not the expected number of search results ${testContext.searchResultList.length}`
        )
        TestCore.testComment('Found search results')
      },
    },
    {
      name: 'Clear the document',
      callDelegate: async () => {
        WordUtils.ClearDocument()
      },
    },
    {
      name: 'Insert test document',
      callDelegate: async () => {
        WordUtils.OverwriteDocumentWithOoxmlContent(testContent)
      },
    },
    {
      name: 'Hit refresh button',
      callDelegate: async () => {
        const button = document.querySelector(
          '[title="Refresh Search Results"]'
        ) as HTMLButtonElement
        button.click()
      },
    },
    {
      name: 'Find the matches',
      callDelegate: async (testContext: any) => {
        /* Step 3 */
        const expectedNumberOfEntries = 9
        // eslint-disable-next-line no-param-reassign
        testContext.searchResultList =
          await testContext.this.GetAllListEntriesAndWaitUntilComplete(
            'search-result-ui-list',
            expectedNumberOfEntries
          )
        TestCore.testComment('Found search list')
        testContext.this.ExpectStringToBe(
          `${testContext.searchResultList.length}`,
          `${expectedNumberOfEntries}`,
          `Not the expected number of search results ${testContext.searchResultList.length}`
        )
        TestCore.testComment('Found search results')
      },
    },
    {
      name: 'Select each match',
      callDelegate: async (testContext: any) => {
        /* Step 4 */
        for (
          let fCounter = 0;
          fCounter < testContext.searchResultList.length;
          fCounter += 1
        ) {
          const child = testContext.searchResultList[fCounter]
            .children[0] as HTMLButtonElement
          child.click()
          // eslint-disable-next-line no-await-in-loop
          await TestCore.delay(1250)
          // eslint-disable-next-line no-await-in-loop
          const selectedValue = (await getCurrentSelection())?.trim()
          /* Fetching the value from the current UI element is specific for the current div hierarchie within a single list entry.
          The task is to fetch the bold printed area. Track this and change to better solution later.
          */
          const listEntryValue =
            child.children[0].children[0].children[1].textContent?.trim()
          testContext.this.ExpectStringToBe(selectedValue, listEntryValue)
          // eslint-disable-next-line no-await-in-loop
          await TestCore.delay(250)
        }
      },
    },
  ],
}

export default [Scenario1]
