import TokenManager from '@xpect-ai/ms-graph-api-token-manager'

export default class GraphHelper {
  // If any part of queryParamsSegment comes from user input,
  // be sure that it is sanitized so that it cannot be used in
  // a Response header injection attack.

  domain: string

  apiVersion: string

  constructor(domain?: string | undefined, apiVersion?: string | undefined) {
    this.domain = domain || 'https://graph.microsoft.com'
    this.apiVersion = apiVersion || '/v1.0'
  }

  /* From https://medium.com/to-err-is-aaron/detect-network-failures-when-using-fetch-40a53d56e36
     Corrected by https://kgrz.io/avoiding-memory-leaks-timing-out-fetch.html
     With respect to https://dev.to/whatthehanan/implement-request-timeout-using-fetch-and-abortcontroller-4gbj */
  static async fetchWithTimeout(
    input: RequestInfo,
    init?: RequestInit | undefined,
    timeout: number = 15000
  ): Promise<Response> {
    let timeoutId: NodeJS.Timeout | null = null
    try {
      /* Controller to abort the connection together with timeout */
      let controller: any = { signal: undefined, abort: () => {} }
      if (window.AbortController) controller = new AbortController()
      /* Either fetch or timeout ends first, the other one will be aborted */
      const response = (await Promise.race([
        fetch(input, {
          ...init,
          signal: controller.signal,
        }),
        new Promise((_, reject) => {
          timeoutId = setTimeout(() => {
            controller.abort()
            reject(new Error('Timeout'))
          }, timeout)
        }),
      ])) as Response
      return response
    } catch (e) {
      let errorMessage = ''
      if (typeof e === 'string') {
        errorMessage = e
      } else if (e instanceof Error) {
        errorMessage = e.message
      }

      if (
        errorMessage === 'Timeout' ||
        errorMessage === 'Network request failed' ||
        errorMessage === 'Failed to fetch'
      ) {
        throw new Error('Network issue')
      } else {
        throw e
      }
    } finally {
      /* Stop Timeout function */
      if (timeoutId) clearTimeout(timeoutId)
    }
  }

  async getData(
    apiURLsegment: string,
    // If any part of queryParamsSegment comes from user input,
    // be sure that it is sanitized so that it cannot be used in
    // a Response header injection attack.
    queryParamsSegment: string,
    contentType: string = 'application/json',
    customHeaders: any = {}
  ): Promise<any> {
    const accessToken = await TokenManager.getInstance().getToken()

    if (!accessToken) {
      throw new Error('token Fetch failed')
    }

    return new Promise((resolve: any, reject: any) => {
      const options = {
        method: 'GET',
        headers: {
          'Content-Type': contentType,
          Accept: contentType,
          Authorization: `Bearer ${accessToken}`,
          'Cache-Control': 'private, no-cache, no-store, must-revalidate',
          Expires: '-1',
          Pragma: 'no-cache',
          ...customHeaders,
        },
      }
      const path =
        this.domain + this.apiVersion + apiURLsegment + queryParamsSegment
      GraphHelper.fetchWithTimeout(path, options)
        .then(async (response) => {
          // The response from the OData endpoint might be an error, say a
          // 401 if the endpoint requires an access token and it was invalid
          // or expired. But a message is not an error in the call of https.get,
          // so the "on('error', reject)" line below isn't triggered.
          // So, the code distinguishes success (200) messages from error
          // messages and sends a JSON object to the caller with either the
          // requested OData or error information.
          if (response.ok) {
            let parsedBody: any
            switch (contentType) {
              case 'image/jpeg':
                parsedBody = await response.blob()
                break
              default:
                parsedBody = await response.json()
            }
            resolve(parsedBody)
          } else {
            // const error = Response.error()
            // error.status = response.status
            // error.message = response.statusMessage

            // // The error body sometimes includes an empty space
            // // before the first character, remove it or it causes an error.
            // body = body.trim()
            // error.bodyCode = JSON.parse(body).error.code
            // error.bodyMessage = JSON.parse(body).error.message
            resolve(response)
          }
        })
        .catch((ex) => {
          console.debug(`Error during graph fetch! ${ex}`)
          reject(new Error(ex))
        })
    })
  }

  public async getGraphData(
    apiURLsegment: string,
    queryParamsSegment: string,
    contentType: string = 'application/json',
    customHeaders: any = {}
  ) {
    try {
      const retVal = await this.getData(
        apiURLsegment,
        queryParamsSegment,
        contentType,
        customHeaders
      )
      return retVal
    } catch (error) {
      // @ts-ignore
      throw new Error(`Unable to call Microsoft Graph. ${error.toString()}`)
    }
  }

  async postData(
    apiURLsegment: string,
    // If any part of queryParamsSegment comes from user input,
    // be sure that it is sanitized so that it cannot be used in
    // a Response header injection attack.
    queryParamsSegment: string,
    payload: string
  ) {
    const accessToken = await TokenManager.getInstance().getToken()
    const path =
      this.domain + this.apiVersion + apiURLsegment + queryParamsSegment

    if (!accessToken) {
      throw new Error('token Fetch failed')
    }

    const options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${accessToken}`,
        'Cache-Control': 'private, no-cache, no-store, must-revalidate',
        Expires: '-1',
        Pragma: 'no-cache',
      },
      body: payload,
    }

    const response = await GraphHelper.fetchWithTimeout(path, options)

    // The response from the OData endpoint might be an error, say a
    // 401 if the endpoint requires an access token and it was invalid
    // or expired. But a message is not an error in the call of https.get,
    // so the "on('error', reject)" line below isn't triggered.
    // So, the code distinguishes success (200) messages from error
    // messages and sends a JSON object to the caller with either the
    // requested OData or error information.
    if (response.ok) {
      const parsedBody = await response.json()
      return parsedBody
    }
    return response
  }

  public async postGraphData(
    apiURLsegment: string,
    queryParamsSegment: string,
    payload: string
  ) {
    try {
      const retVal = await this.postData(
        apiURLsegment,
        queryParamsSegment,
        payload
      )
      return retVal
    } catch (error) {
      console.debug(`Unable to call Microsoft Graph ${error}`)
      // @ts-ignore
      throw error
    }
  }
}
