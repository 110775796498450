import { IEntity } from '../interfaces/interfaces'
import * as Actions from './selectionActions'
import SelectionState from './selectionState'

const initialState: SelectionState = {
  selection: new Map(),
  mostRecentlyUsed: [],
}

function addNew(existing: IEntity[], update: IEntity[]): IEntity[] {
  // TODO: Consider only delta selection and move already existing in mru to front on re-addign
  return [
    ...update.filter((e1) => !existing.find((e2) => e1.id === e2.id)),
    ...existing,
  ]
}

export default (
  // eslint-disable-next-line @typescript-eslint/default-param-last
  state: SelectionState = initialState,
  action: Actions.ActionTypes
): SelectionState => {
  switch (action.type) {
    case Actions.REMOVE_FROM_MRU:
      return {
        ...state,
        mostRecentlyUsed: state.mostRecentlyUsed.filter(
          (entity) => entity.id !== action.entity.id
        ),
      }
    case Actions.SET_ENTITY_SELECTION:
      return {
        ...state,
        selection: state.selection.set(
          action.role || 'default',
          action.selection
        ),
        mostRecentlyUsed: addNew(state.mostRecentlyUsed, action.selection),
      }
    default:
      return state
  }
}
