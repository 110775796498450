import { MessageBar } from '@fluentui/react'
import React, { Fragment } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import * as Actions from './msgBarActions'
import { getMsgBars } from './msgBarsSelectors'

export const MsgBars: React.FC = () => {
  const msgBars = useSelector(getMsgBars)
  const dispatch = useDispatch()

  return (
    <Fragment>
      {msgBars.map((mb, idx) => (
        <MessageBar
          key={`mbar-${idx}`}
          messageBarType={mb.type}
          actions={mb.actions}
          onDismiss={
            mb.hasDismissBtn
              ? () => dispatch({ type: Actions.DISMISS_MSG_BAR, msgBar: mb })
              : undefined
          }
          dismissButtonAriaLabel="Close"
          isMultiline={mb.isMultiline || true}
        >
          {mb.content}
        </MessageBar>
      ))}
    </Fragment>
  )
}
