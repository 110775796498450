import Match from './Match'
import SearchResults from './SearchResults'
import ShadowDocument from './ShadowDocument'

import type { IMatch } from '../interfaces/interfaces'

function findMatchPositions(results: SearchResults, document: ShadowDocument) {
  const matchPositions: any = []
  let currentPos = 0
  results.matches.forEach((m) => {
    const mpos =
      document.findNextMatchPosition(
        m.searchResults.searchSettings,
        m.text,
        currentPos
      ) + currentPos
    if (mpos < currentPos) {
      // didn't find a match. that shouldn't happen so we abort
      throw new Error('could not find position of match in document. aborting')
    }
    matchPositions.push([mpos, m])
    currentPos = mpos + m.text.length
  })
  return matchPositions
}

// reduce-function that removes sub-matches. removes all matches that overlap
// an earlier match.
//
// assumes the array it's applied to
// * is sorted in order of start position and (inverse) size of match
function stripSubMatches(result: [number, Match][], current: [number, Match]) {
  if (result.length === 0) return [current]

  const prev = result[result.length - 1]
  const prevEnd = prev[0] + prev[1].text.length

  if (current[0] >= prevEnd) return [...result, current]

  return result
}

/**
 * Combines matches of several searches and sort them in order of occurances
 * in the document.
 */
async function mergeMatches(results: SearchResults[]): Promise<Match[]> {
  // NOTE(esc): to sort matches of the different searches, we need to figure
  // out their relative locations in the document. Using range-compare functions
  // of Word is far too expensive (they are async!), so we fetch the document
  // content and figure out the locations on our own by relying on Javascript's
  // pattern matching
  const shadowDocument = await ShadowDocument.newInstance()

  const mergedMatchesWithPos = results.flatMap((rs) =>
    findMatchPositions(rs, shadowDocument)
  )

  // sort by position. if start position is equal, put longest match first. it's easier
  // to remove sub-matches that way
  mergedMatchesWithPos.sort(
    (a, b) => a[0] - b[0] || b[1].text.length - a[1].text.length
  )

  const filteredMatchesWithPos = mergedMatchesWithPos.reduce(
    stripSubMatches,
    []
  )

  return filteredMatchesWithPos.map((m: [number, Match]) => m[1])
}

/** A collection of SearchResults. */
export default class SearchResultsGroup {
  searches: SearchResults[] = []

  /** combined matches in order of position within the document */
  matches: Match[] = []

  private constructor(searches: SearchResults[], matches: Match[]) {
    this.searches = searches
    this.matches = matches
  }

  /**
   * Checks whether the contained search results are still valid given the current
   * state of the Word document. They might become invalid by changes made
   * to the document after the results were last synced.
   */
  async isInSyncWithDocument(): Promise<Boolean> {
    return Promise.all(this.searches.map((s) => s.isInSyncWithDocument())).then(
      (vs) => vs.every((v) => v)
    )
  }

  /**
   * Replaces matches in the document with the supplied replacement texts.
   *
   * @param replacements Mapping of matches to replacement strings
   */
  replaceMatchesWith(
    replacements: Map<IMatch, string | undefined>
  ): Promise<SearchResultsGroup> {
    return Word.run(
      // NOTE(esc): what we're doing here requires all contained searches to use
      // the same context. this first argument allows Word to check this. will fail
      // if there is more than one context being used for the searches.
      this.searches.map((sr) => sr.rangeCollection),
      async (context) => {
        // only replaces matches that are part of this results group
        for (let i = this.matches.length - 1; i >= 0; i -= 1) {
          const m = this.matches[i]
          if (replacements?.get(m) !== undefined)
            m.range.insertText(replacements.get(m) as string, 'Replace')
        }
        await context.sync()
      }
    ).then(() => this.sync())
  }

  /**
   * Updates the search results according to any document changes performed since
   * the last sync.
   */
  sync(): Promise<SearchResultsGroup> {
    return Promise.all(this.searches.map((s) => s.sync()))
      .then(() => mergeMatches(this.searches))
      .then((matches) => {
        this.matches = matches
        return this
      })
  }

  /**
   * Frees all resources bound by the contained SearchResults in the
   * host application (Word).
   */
  free(): Promise<void> {
    return Promise.all(this.searches.map((s) => s.free())).then(() => {})
  }

  static newInstance(searches: SearchResults[]): Promise<SearchResultsGroup> {
    return mergeMatches(searches).then(
      (matches) => new SearchResultsGroup(searches, matches)
    )
  }
}
