import React from 'react'
import ReactDOM from 'react-dom'
import { useDispatch, useSelector } from 'react-redux'
import {
  DefaultButton,
  FocusZone,
  FocusZoneDirection,
  IconButton,
  IIconProps,
  IList,
  List,
  MessageBarButton,
  MessageBarType,
  ScrollToMode,
  Stack,
} from '@fluentui/react'

import { IEntity, IMatch } from '../interfaces/interfaces'
import { getSelection } from '../entityLookUp/selectionSelectors'
import { searchForEntityData, SearchResultsGroup } from '.'
import Match from './Match'
import MatchCell from './MatchCell'
import * as Actions from '../msgBars/msgBarActions'
import measureElement from './measureElement'

const upIcon: IIconProps = { iconName: 'ChevronUp' }
const downIcon: IIconProps = { iconName: 'ChevronDown' }
const refreshIcon: IIconProps = { iconName: 'Refresh' }

interface IMatchListItem {
  match: Match
  isSelected: boolean
  replacement?: string
}

/*
 * Helper functions
 */
const createListItems = (results: SearchResultsGroup, selectedIndex?: number) =>
  results.matches.map((match, idx) => ({
    match,
    isSelected: idx === selectedIndex,
  }))

const getPropertyValue = (
  propertyName: string,
  item?: IEntity
): string | undefined =>
  item && item.properties.find((p) => p.name === propertyName)?.value

const applyDefaultReplacements = (
  items: IMatchListItem[],
  replaceItem?: IEntity
): IMatchListItem[] => {
  return items.map((item) => ({
    ...item,
    replacement: getPropertyValue(item.match.propertyName, replaceItem),
  }))
}

const MatchList: React.FunctionComponent = () => {
  const searchItems = useSelector(getSelection('search'))
  const replaceItems = useSelector(getSelection('replace'))

  const [listItems, setListItems] = React.useState<IMatchListItem[]>([])

  const searchResults = React.useRef<SearchResultsGroup>()
  const lastSearchItem = React.useRef<IEntity>()
  const lastReplaceItem = React.useRef<IEntity>()
  const selectedIndex = React.useRef<number>(-1)

  const ref = React.useRef(null)
  const listRef = React.useRef<IList>(null)

  const dispatch = useDispatch()

  const generateListItems = (
    results: SearchResultsGroup,
    selection?: number
  ): IMatchListItem[] => {
    let items = createListItems(results, selection)
    if (lastReplaceItem) {
      items = applyDefaultReplacements(items, lastReplaceItem.current)
    }
    return items
  }

  // checks whether the document matches are still up-to-date. retrieves
  // new matches if not. returns true iff a resync was necessary
  const resyncResults = async (
    warningText: string,
    warningTimeoutMs: number,
    selection?: number
  ) => {
    if (!(await searchResults.current?.isInSyncWithDocument())) {
      dispatch({
        type: Actions.SHOW_MSG_BAR,
        msgBar: {
          type: MessageBarType.error,
          timeout: warningTimeoutMs,
          content: <span>{warningText}</span>,
          actions: undefined,
          hasDismissBtn: true,
          isMultiline: false,
        },
      })
      await searchResults.current?.sync().then((results) => {
        if (
          selection !== undefined &&
          selection >= 0 &&
          selection < results.matches.length
        ) {
          results.matches[selection].becomeActiveSelection()
          selectedIndex.current = selection
        }
        setListItems(generateListItems(results, selection))
      })
      return true
    }
    return false
  }

  const changeSelection = (selection: number) => {
    // ignore if there aren't any results to select
    if (!searchResults.current) return

    try {
      if (selection >= 0 && selection < searchResults.current.matches.length) {
        searchResults.current.matches[selection].becomeActiveSelection()
        selectedIndex.current = selection
      }
      setListItems(generateListItems(searchResults.current, selection))

      listRef.current?.scrollToIndex(
        selection,
        /* eslint-disable-next-line @typescript-eslint/no-use-before-define */
        measureItem,
        ScrollToMode.center
      )
    } catch (e) {
      console.debug('the current selection is invalid. waiting for resync')
      console.debug(e)
    }

    resyncResults(
      'Document content has changed. Resetting result list.',
      3000,
      selection
    )
  }

  const updateReplacement = (
    match: IMatch,
    replacement?: string,
    all = false
  ): void => {
    const items = listItems.map((item) =>
      item.match === match ||
      (all && match.propertyName === item.match.propertyName)
        ? {
            ...item,
            replacement,
          }
        : item
    )
    if (!all) {
      /* eslint-disable-next-line @typescript-eslint/no-use-before-define */
      queryUserForReplaceAll(match, replacement)
    }
    setListItems(items)
  }

  const queryUserForReplaceAll = (match: IMatch, newReplacement?: string) => {
    dispatch({
      type: Actions.SHOW_MSG_BAR,
      msgBar: {
        type: MessageBarType.warning,
        timeout: 5000,
        content: (
          <span>
            Substitute all <i>{match.propertyName}</i>?
          </span>
        ),
        actions: (
          <MessageBarButton
            onClick={() => updateReplacement(match, newReplacement, true)}
          >
            Yes
          </MessageBarButton>
        ),
        hasDismissBtn: true,
        isMultiline: false,
      },
    })
  }

  const commitReplace = async () => {
    if (
      !(await resyncResults(
        'Document content has changed. Aborting replace.',
        5000,
        selectedIndex.current
      ))
    ) {
      const rep = new Map<IMatch, string | undefined>()
      listItems.forEach((item) => rep.set(item.match, item.replacement))
      searchResults.current
        ?.replaceMatchesWith(rep)
        .then((results) => {
          searchResults.current = results
          selectedIndex.current = -1
          setListItems(generateListItems(results))
        })
        .catch((error) => {
          console.debug('replacement failed')
          console.debug(error)
          dispatch({
            type: Actions.SHOW_MSG_BAR,
            msgBar: {
              type: MessageBarType.error,
              timeout: 10000,
              content: (
                <span>
                  Unable to replace all matches. This was likely caused by a
                  change in the document while the replace operation was in
                  progress. Use Word's <b>Undo</b> to revert partial
                  replacements.
                </span>
              ),
              actions: undefined,
              hasDismissBtn: true,
              isMultiline: true,
            },
          })
        })
    }
  }

  const renderCell = (item?: IMatchListItem, index?: number) =>
    item &&
    index !== undefined && (
      <MatchCell
        match={item.match}
        selected={item.isSelected}
        // TODO (ms): Check what happens if lastReplaceItem is updated but has the same default fields? Does rerender fail and will this cause "old" alternatives to stay in the dropdown?
        replaceEntity={lastReplaceItem.current}
        replacement={item.replacement}
        onCellSelection={() => changeSelection(index)}
        onReplacementUpdate={(newReplacement) =>
          updateReplacement(item.match, newReplacement)
        }
      />
    )

  const measureItem = (idx: number) => {
    if (ref.current) {
      const element = ReactDOM.findDOMNode(ref.current) as Element
      return measureElement(
        renderCell(listItems[idx], idx) as JSX.Element,
        element?.clientWidth
      ).height
    }
    return 50
  }

  const refreshSearchResults = () => {
    if (lastSearchItem.current) {
      searchForEntityData(lastSearchItem.current)
        .then((results) => {
          searchResults.current = results
          selectedIndex.current = -1
          setListItems(generateListItems(results))
        })
        .catch((ex) => {
          dispatch({
            type: Actions.SHOW_MSG_BAR,
            msgBar: {
              type: MessageBarType.error,
              timeout: 15000,
              content: <span>{`${ex}`}</span>,
              actions: undefined,
              hasDismissBtn: true,
              isMultiline: false,
            },
          })
        })
    }
  }

  // Check if matches need an updated
  const searchItem = searchItems.length > 0 ? searchItems[0] : undefined
  if (searchItem !== lastSearchItem.current) {
    if (searchResults.current) {
      searchResults.current.free()
      searchResults.current = undefined
      selectedIndex.current = -1
      setListItems([])
    }
    lastSearchItem.current = searchItem
    if (searchItem) {
      refreshSearchResults()
    }
  }

  // Check if replacements need an update
  const replaceItem = replaceItems.length > 0 ? replaceItems[0] : undefined
  if (replaceItem !== lastReplaceItem.current) {
    lastReplaceItem.current = replaceItem
    setListItems(applyDefaultReplacements(listItems, replaceItem))
  }

  const enableReplace = !!listItems.find(
    (item) => item.replacement !== undefined
  )

  return (
    <div className="flex-stretch flex-container">
      <div className="flex-fixed">
        <Stack horizontal className="sarRow" verticalAlign="center">
          {selectedIndex.current < 0 ? (
            <Stack.Item grow className="vCenter">
              {listItems.length} Result{listItems.length === 1 ? '' : 's'}
            </Stack.Item>
          ) : (
            <Stack.Item grow className="vCenter">
              Result {selectedIndex.current + 1} of {listItems.length}
            </Stack.Item>
          )}
          <IconButton
            iconProps={refreshIcon}
            title="Refresh Search Results"
            ariaLabel="Refresh Search Results"
            disabled={!searchItem}
            onClick={refreshSearchResults}
          />
          <IconButton
            iconProps={upIcon}
            title="Previous Match"
            ariaLabel="Previous Match"
            disabled={selectedIndex.current <= 0}
            onClick={() => changeSelection(selectedIndex.current - 1)}
          />
          <IconButton
            iconProps={downIcon}
            title="Next Match"
            ariaLabel="Next Match"
            disabled={selectedIndex.current >= listItems.length - 1}
            onClick={() => changeSelection(selectedIndex.current + 1)}
          />
        </Stack>
      </div>
      <div className="no-overflow">
        <FocusZone direction={FocusZoneDirection.vertical} className="h100">
          <div className="scroll h100" data-is-scrollable>
            <List
              id="search-result-ui-list"
              items={listItems}
              onRenderCell={renderCell}
              ref={ref}
              componentRef={listRef}
              // getPageHeight={getPageHeight}
            />
          </div>
        </FocusZone>
      </div>
      {lastSearchItem.current && lastReplaceItem.current && (
        <div className="sarRow flex-fixed mt10">
          <DefaultButton
            id="ui-button-replace-all-matches"
            text="Replace"
            onClick={() => commitReplace()}
            disabled={!enableReplace}
          />
        </div>
      )}
    </div>
  )
}

export default MatchList
