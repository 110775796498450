import React, { Fragment } from 'react'

import TestCore from '@xpect-ai/e2e-test-framework-react'

import { Home } from '../features/homePage/Home'

import Tests from './tests'

interface IProps {}

interface IState {
  testState: string
}

export class E2EPage extends React.Component<IProps, IState> {
  testCore: TestCore

  constructor(props: any) {
    super(props)
    this.testCore = new TestCore(this.updateTestState.bind(this))
    // Don't call this.setState() here!
    this.state = { testState: 'not executed' }
  }

  updateTestState(newTestStateString: string) {
    this.setState({ testState: newTestStateString })
  }

  componentDidMount() {
    this.testCore.performTests(Tests)
  }

  render() {
    return (
      <Fragment>
        <p>E2E Test: {this.state.testState}</p>
        <Home />
      </Fragment>
    )
  }
}
