/** Represents a single search hit in a document. */
import { IMatch } from '../interfaces/interfaces'

import type SearchResults from './SearchResults'

export default class Match implements IMatch {
  /** the results this match belongs to */
  searchResults: SearchResults

  range: Word.Range

  /** text of this match */
  text: string = ''

  /** text immediately before the match */
  textBeforeMatch: string = ''

  /** text immediately after the match */
  textAfterMatch: string = ''

  propertyName: string = ''

  constructor(
    searchResults: SearchResults,
    range: Word.Range,
    text: string,
    beforeMatch: string,
    afterMatch: string,
    propertyName: string
  ) {
    this.searchResults = searchResults
    this.range = range
    this.text = text
    this.textBeforeMatch = beforeMatch
    this.textAfterMatch = afterMatch
    this.propertyName = propertyName
  }

  get searchPattern() {
    return this.searchResults.searchPattern
  }

  get tags() {
    return this.searchResults.tags
  }

  /**
   * Makes this match the current selection in the document UI.
   *
   * Beware: This will fail if the underlying document was changed in
   * a way that invalidated this match.
   */
  becomeActiveSelection(): Promise<IMatch> {
    return Word.run(this.range, async (context) => {
      this.range.select()
      return context.sync(this)
    })
  }
}
