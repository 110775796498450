import React, { Fragment } from 'react'
import {
  CommandButton,
  ContextualMenuItemType,
  getTheme,
  IContextualMenuItem,
  ITheme,
  mergeStyleSets,
} from '@fluentui/react'

import { IEntity, IMatch } from '../interfaces/interfaces'

interface IMatchCellProps {
  match: IMatch
  selected: boolean
  replaceEntity?: IEntity
  replacement?: string
  onCellSelection?: () => void
  onReplacementUpdate?: (newReplacement: string | undefined) => void
}

const MatchCell: React.FunctionComponent<IMatchCellProps> = ({
  match,
  selected,
  replaceEntity,
  replacement,
  onCellSelection,
  onReplacementUpdate,
}) => {
  const theme: ITheme = getTheme()
  const { palette, semanticColors } = theme

  const classNames = mergeStyleSets({
    itemCell: [
      {
        padding: '12px 12px 11px',
        borderBottom: `1px solid ${semanticColors.bodyDivider}`,
        selectors: {
          '&:hover': { background: palette.neutralLight },
        },
      },
    ],
    itemCellSelected: [
      {
        padding: '10px',
        border: `2px solid #787878`,
        selectors: {
          '&:hover': { background: palette.neutralLight },
        },
      },
    ],
    itemContent: {
      overflow: 'hidden',
      wordWrap: 'break-word',
      overflowWrap: 'break-word',
    },
  })

  const contextMenuItems: IContextualMenuItem[] = [
    ...(replaceEntity?.properties || [])
      .filter((p) => p.name === match.propertyName)
      .map((p, idx) => ({
        key: `r${idx}`,
        text: p.value,
        iconProps:
          replacement === p.value ? { iconName: 'CheckMark' } : undefined,
        onClick: () => onReplacementUpdate && onReplacementUpdate(p.value),
      })),
    {
      key: 'div',
      itemType: ContextualMenuItemType.Divider,
    },
    {
      key: 'dnr',
      text: 'Do not replace',
      iconProps:
        replacement === undefined ? { iconName: 'CheckMark' } : undefined,
      onClick: () => onReplacementUpdate && onReplacementUpdate(undefined),
    },
  ]

  return (
    match && (
      <div
        role="button"
        tabIndex={0}
        className={selected ? classNames.itemCellSelected : classNames.itemCell}
        aria-label={match.text}
        data-is-focusable
        onClick={onCellSelection}
        onKeyDown={onCellSelection}
      >
        <div className={classNames.itemContent} title={match.propertyName}>
          <div>
            <span>...{match.textBeforeMatch} </span>
            {replacement ? (
              <Fragment>
                <span className="replaceOld">{match.text}</span>
                <span className="replaceNew">{replacement}</span>
              </Fragment>
            ) : (
              <b>{match.text}</b>
            )}
            {replaceEntity && (
              <CommandButton
                menuProps={{ items: contextMenuItems }}
                className="replaceCommand"
                tabIndex={-1}
              />
            )}
            <span> {match.textAfterMatch}...</span>
          </div>
        </div>
      </div>
    )
  )
}

export default MatchCell
