import { IEntity, IProperty } from '../interfaces/interfaces'
import GraphHelper from '../../services/msgraph/GraphHelper'

/**
 * Interfaces to a single search result from MS Graph
 */
interface SearchResult {
  hitId: string

  rank: number

  summary: string

  resource: {
    [name: string]: string | any
  }
}

/**
 * Parses a search result as returned from MS Graph API into an Entity object
 * @param hit single Search Result from Graph API
 * @returns Search result parsed as an entity
 */
function parseSearchResultToEntity(hit: SearchResult): IEntity {
  const properties: Array<IProperty> = []

  Object.keys(hit.resource).forEach((name) => {
    const value = hit.resource[name]
    if (typeof value === 'string') {
      properties.push({ name, value })
    } else if (name === 'phones') {
      value.forEach((number: { type: string; number: string }) => {
        properties.push({ name: `phone.${number.type}`, value: number.number })
      })
    } else {
      properties.push({ name, value: JSON.stringify(value) })
    }
  })

  return {
    id: hit.hitId,
    displayName: hit.resource.displayName || hit.hitId,
    properties,
  }
}

function blobToBase64(blob: Blob): Promise<string> {
  return new Promise((resolve) => {
    const reader = new FileReader()
    reader.readAsDataURL(blob)
    reader.onloadend = () => {
      if (reader.result) {
        const url = reader.result.toString()
        resolve(url)
      }
      resolve('')
    }
    reader.onerror = () => {
      resolve('')
    }
  })
}

async function augmentWithPhotos(
  graph: GraphHelper,
  entities: IEntity[]
): Promise<IEntity[]> {
  // adding images in the returned list of promises
  return Promise.all(
    entities
      .filter((entity) => !entity.id.endsWith('=='))
      .map(async (entity) => {
        const photoBlob = await graph.getGraphData(
          `/users/${entity.id}/photo/`,
          '$value',
          'image/jpeg'
        )
        let photoUrl: string = ''
        if (!photoBlob.status || photoBlob.status === 200) {
          photoUrl = await blobToBase64(photoBlob)
        }
        entity.properties.push({
          name: 'photo',
          value: photoUrl,
        })
        return entity
      })
  )
}

/**
 * Perform a single Graph API Search for Users via "Search" endpoint
 * @param searchString String to send to MS Graph API
 * @returns Array of entities
 */
export default async function getEntities(
  searchString: string
): Promise<Array<IEntity>> {
  const graph = new GraphHelper()

  const payload = JSON.stringify({
    requests: [
      {
        entityTypes: ['person'],
        query: {
          queryString: searchString,
        },
      },
    ],
  })

  const graphData: any = await graph.postGraphData('/search/query', '', payload)

  if (graphData.code) {
    throw new Error(graphData)
  }

  const entities: IEntity[] = (
    graphData.value[0].hitsContainers[0].hits || []
  ).map((hit: SearchResult) => parseSearchResultToEntity(hit))

  // adding images in the returned list of promises
  return augmentWithPhotos(graph, entities)
}

/**
 * Perform a single Graph API Search for Users via "Users2 endpoint
 * @param searchString String to send to MS Graph API
 * @returns Array of entities
 */
export async function getEntitiesFromUsers(
  searchString: string
): Promise<Array<IEntity>> {
  const graph = new GraphHelper()

  const encodedSearch = encodeURIComponent(searchString)
  const searchQuery = [
    'displayName',
    'givenName',
    'surname',
    // 'jobTitle',
    'mail',
    // 'mobilePhone',
    // 'officeLocation',
  ]
    .map((p) => `"${p}:${encodedSearch}"`)
    .join(' OR ')

  const graphData: any = await graph.getGraphData(
    '/users',
    `?$search=${searchQuery}`,
    undefined,
    { ConsistencyLevel: 'eventual' }
  )

  if (graphData.code) {
    throw new Error(graphData)
  }

  const entities: IEntity[] = (graphData.value || []).map(
    (result: any, idx: number) =>
      parseSearchResultToEntity({
        hitId: result.id,
        resource: result,
        summary: result.displayName,
        rank: idx,
      })
  )

  // adding images in the returned list of promises
  return augmentWithPhotos(graph, entities)
}
