import * as Actions from './msgBarActions'
import MsgBarsState from './msgBarsState'

const initialState: MsgBarsState = {
  msgBars: [],
}

export default (
  // eslint-disable-next-line @typescript-eslint/default-param-last
  state: MsgBarsState = initialState,
  action: Actions.ActionTypes
): MsgBarsState => {
  switch (action.type) {
    case Actions.SHOW_MSG_BAR:
      if ((action.msgBar.timeout || 0) > 0) {
        setTimeout(
          () =>
            (action as any).asyncDispatch({
              type: Actions.DISMISS_MSG_BAR,
              msgBar: action.msgBar,
            }),
          action.msgBar.timeout
        )
      }
      return {
        ...state,
        msgBars: [action.msgBar, ...state.msgBars],
      }
    case Actions.DISMISS_MSG_BAR:
      return {
        ...state,
        msgBars: state.msgBars.filter((mb) => mb !== action.msgBar),
      }
    default:
      return state
  }
}
