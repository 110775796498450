import { Middleware } from 'redux'
import { RootState } from './state'

// This middleware will just add the property "async dispatch" to all actions
export const asyncDispatchMiddleware: Middleware<{}, RootState> =
  (store) => (next) => (action) => {
    let syncActivityFinished = false
    let actionQueue: any[] = []

    function flushQueue() {
      actionQueue.forEach((a) => store.dispatch(a)) // flush queue
      actionQueue = []
    }

    function asyncDispatch(asyncAction: any) {
      actionQueue = actionQueue.concat([asyncAction])

      if (syncActivityFinished) {
        flushQueue()
      }
    }

    const actionWithAsyncDispatch = { ...action, asyncDispatch }

    const res = next(actionWithAsyncDispatch)

    syncActivityFinished = true
    flushQueue()

    return res
  }
