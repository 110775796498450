import { IEntity } from '../interfaces/interfaces'

export const REMOVE_FROM_MRU = 'REMOVE_FROM_MRU'
export const SET_ENTITY_SELECTION = 'SET_ENTITY_SELECTION'

interface RemoveFromMRUAction {
  type: typeof REMOVE_FROM_MRU
  entity: IEntity
}

interface SetEntitySelectionAction {
  type: typeof SET_ENTITY_SELECTION
  role?: string
  selection: IEntity[]
}

export type ActionTypes = RemoveFromMRUAction | SetEntitySelectionAction
