import { applyMiddleware, combineReducers, compose, createStore } from 'redux'
import devToolsEnhancer from 'remote-redux-devtools'
import selectionReducer from '../features/entityLookUp/selectionReducer'
import msgBarsReducer from '../features/msgBars/msgBarsReducer'
import authReducer from '../features/authFlow/authReducer'
import { asyncDispatchMiddleware } from './asyncDispatchMiddleware'

/* Create root reducer, containing all features of the application */
const rootReducer = combineReducers({
  selection: selectionReducer,
  msgBars: msgBarsReducer,
  authentication: authReducer,
})

const middleware = applyMiddleware(asyncDispatchMiddleware)

const enhancer =
  process.env.REACT_APP_REDUX_REMOTE_DEVTOOLS === 'true'
    ? compose(
        middleware,
        devToolsEnhancer({
          realtime: true,
          name: 'Personal Data Search and Replace',
          hostname: 'localhost',
          port: 8000,
        })
      )
    : middleware

export function newStore() {
  return createStore(rootReducer, enhancer)
}

export const store = newStore()
