import React from 'react'
import { Provider } from 'react-redux'
import { ErrorBoundary } from '@sentry/react'

import { E2EPage } from '../e2etests/E2EPage'

import { Home } from '../features/homePage/Home'

import * as Store from './store'
import { MsgBars } from '../features/msgBars/MsgBars'
import { AuthSplash } from '../features/authFlow/AuthSplash'
import { UserErrorDisplay } from '../features/errorHandling/UserErrorDisplay'

function getCurrentSelection(): Promise<string | undefined> {
  return Word.run(async (context) => {
    const range = context.document.getSelection()
    range.load('text')
    await context.sync()
    return range.text
  })
}

const App: React.FunctionComponent = () => {
  const [defaultQuery, setDefaultQuery] = React.useState<string>()

  React.useEffect(() => {
    getCurrentSelection().then((selectedText) => {
      setDefaultQuery(
        selectedText && selectedText.length > 0 ? selectedText : undefined
      )
    })
  }, [])

  return (
    <ErrorBoundary fallback={({ error }) => <UserErrorDisplay error={error} />}>
      <Provider store={Store.store}>
        <div className="flex-container root-container">
          <AuthSplash>
            {!(
              window.location.toString().split('?')[0].endsWith('e2e') &&
              process.env.REACT_APP_INCLUDE_E2E_ROUTING === 'true'
            ) ? (
              <Home defaultQuery={defaultQuery} />
            ) : (
              <E2EPage />
            )}
          </AuthSplash>
          <div className="flex-fixed">
            <MsgBars />
          </div>
        </div>
      </Provider>
    </ErrorBoundary>
  )
}

export default App
