import * as Actions from './authActions'
import AuthenticationState from './authState'

const initialState: AuthenticationState = {
  isAuthenticated: false,
}
export default (
  // eslint-disable-next-line @typescript-eslint/default-param-last
  state: AuthenticationState = initialState,
  action: Actions.ActionTypes
): AuthenticationState => {
  switch (action.type) {
    case Actions.SET_AUTH_STATE:
      return {
        ...state,
        isAuthenticated: action.authenticated,
      }
    default:
      return state
  }
}
