import Match from './Match'
import ShadowDocument from './ShadowDocument'

function extractMatchTextContext(
  searchSettings: Record<string, Boolean>,
  document: ShadowDocument,
  pattern: string,
  startPos: number,
  textContextSize: number
) {
  const context = document.findNextMatchContext(
    searchSettings,
    pattern,
    startPos,
    textContextSize
  )
  if (!context)
    throw new Error('could not find context of match in document. aborting')

  return {
    pos: context.matchStartPos,
    before: context.beforeMatch,
    after: context.afterMatch,
  }
}

enum SearchResultsStatus {
  NOT_SYNCED,
  VALID,
  FREED,
}

/**
 * Represents a list of text search results in order of their occurances in
 * the underlying document
 */
export default class SearchResults {
  status: SearchResultsStatus = SearchResultsStatus.NOT_SYNCED

  pattern: string

  rangeCollection: Word.RangeCollection

  textContextSize: number

  matches: Match[] = []

  tags = new Set<string>()

  // settings used for the Word document search
  searchSettings: Record<string, boolean>

  propertyName: string

  private constructor(
    pattern: string,
    searchSettings: Record<string, boolean>,
    results: Word.RangeCollection,
    propertyName: string,
    textContextSize = 16
  ) {
    this.pattern = pattern
    this.rangeCollection = results
    this.textContextSize = textContextSize
    this.searchSettings = searchSettings
    this.propertyName = propertyName
  }

  /**
   * Create a new instance asynchronously by performing a search in the current
   * Word document.
   *
   * @param pattern the text pattern to search for (not interpreted as a regex)
   * @param clientObj? an Office ClientObject that is to be used as the request context
   *  provider. Can be used to force ClientObjects that represent the search results to be
   *  owned by an existing context (that of the supplied object).
   */
  static newInstance(
    pattern: string,
    propertyName: string,
    clientObj?: OfficeExtension.ClientObject
  ): Promise<SearchResults> {
    const fn = async (context: Word.RequestContext) => {
      const searchSettings = { matchCase: false, matchWholeWord: true }
      let results = context.document.body.search(pattern, searchSettings)
      results.load('length')
      await context.sync()

      // if we don't get any results with whole-word-search, we fall back to a
      // "free" search. this might lead to unwanted results, but this is preferred to
      // having a low recall.
      //
      // the introduction of this hack was triggered by the whole-word search not finding
      // phone numbers starting with '+', as Word seems to get confused with word boundries
      // if a search pattern starts with a non-word character
      if (results.items.length === 0) {
        searchSettings.matchWholeWord = false
        results = context.document.body.search(pattern, searchSettings)
      }

      context.trackedObjects.add(results)
      return context.sync(
        new SearchResults(pattern, searchSettings, results, propertyName)
      )
    }

    return (clientObj ? Word.run(clientObj, fn) : Word.run(fn)).then(
      (results) => results.syncMatches()
    )
  }

  private checkValid(): void {
    if (this.status === SearchResultsStatus.FREED)
      throw new Error(
        "instance no longer valid. did you try to use it after a call to 'free'?"
      )
  }

  get searchPattern(): string {
    return this.pattern
  }

  /**
   * Checks whether the search results are still valid given the current
   * state of the Word document. They might become invalid by changes made
   * to the document after the results were last synced.
   */
  async isInSyncWithDocument(): Promise<Boolean> {
    return Word.run(this.rangeCollection, async (context) => {
      try {
        this.rangeCollection.items.forEach((i) => i.load('text'))
        await context.sync()

        if (this.rangeCollection.items.length !== this.matches.length)
          return false

        for (let i = 0; i < this.matches.length; i += 1) {
          if (this.matches[i].text !== this.rangeCollection.items[i].text)
            return false
        }

        return true
      } catch (e) {
        console.debug('Word API error. Assuming document is out of sync')
        console.debug(e)
        return false
      }
    })
  }

  /** Replaces all search results with the supplied text. */
  replaceMatchesWith(text: string, exclude?: Match[]): Promise<SearchResults> {
    this.checkValid()
    return Word.run(this.rangeCollection, async (context) => {
      this.matches
        .filter((m: Match) => !exclude || exclude.indexOf(m) < 0)
        .forEach((m: Match) => m.range.insertText(text, 'Replace'))
      return context.sync()
    }).then(() => this.sync())
  }

  /**
   * Frees all resources bound by this instance in the host application (Word).
   *
   * Make sure to call this function if you're no longer in need of this results.
   *
   * Matches of this instance must no longer be accessed after this method has been called
   * as they are likely no longer available in the host, which will lead to exceptions.
   */
  free(): Promise<void> {
    console.debug(`freeing resources of search '${this.pattern}'`)
    return Word.run(this.rangeCollection, (context) => {
      this.status = SearchResultsStatus.FREED
      context.trackedObjects.remove(this.rangeCollection.items)
      context.trackedObjects.remove(this.rangeCollection)
      return context.sync()
    })
  }

  /**
   * Updates the search results according to any document changes performed since
   * the last sync.
   */
  async sync(): Promise<SearchResults> {
    this.checkValid()
    return Word.run(this.rangeCollection, (context) => {
      const newResults = context.document.body.search(
        this.pattern,
        this.searchSettings
      )
      this.status = SearchResultsStatus.NOT_SYNCED
      context.trackedObjects.remove(this.rangeCollection.items)
      context.trackedObjects.remove(this.rangeCollection)
      context.trackedObjects.add(newResults)
      this.rangeCollection = newResults
      return context.sync()
    }).then(async () => this.syncMatches())
  }

  private syncMatches(): Promise<SearchResults> {
    return Word.run(this.rangeCollection, async (context) => {
      this.rangeCollection.load('length, items/text')

      await context.sync()
      context.trackedObjects.add(this.rangeCollection.items)

      return context.sync()
    }).then(async () => {
      const shadowDocument = await ShadowDocument.newInstance()
      let curMatchPos = 0
      this.matches = this.rangeCollection.items.map((item: any) => {
        const textContext = extractMatchTextContext(
          this.searchSettings,
          shadowDocument,
          item.text,
          curMatchPos,
          this.textContextSize
        )
        curMatchPos = textContext.pos + item.text.length
        return new Match(
          this,
          item,
          item.text,
          textContext.before,
          textContext.after,
          this.propertyName
        )
      })
      this.status = SearchResultsStatus.VALID
      return this
    })
  }
}
