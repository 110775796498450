import React from 'react'
import {
  Icon,
  Label,
  TooltipHost,
  ITooltipProps,
  Text,
  mergeStyles,
} from '@fluentui/react'

import EntityPicker from '../entityLookUp/EntityPicker'
import MatchList from '../documentSearch/MatchList'

interface IHome {
  defaultQuery?: string
}

const helpIconClass = mergeStyles({ color: '#0072c6' })

const helpText = (operation: string): ITooltipProps => ({
  onRenderContent: () => (
    <Text>
      Enter name of user who's data to {operation} and select correct user from
      list. User must exist in your organization's user directory.{' '}
      <a
        href="#"
        onClick={() =>
          window.open('https://xpect.ai/mini-products.html#pdsar-entity-search')
        }
      >
        Need more help?
      </a>
    </Text>
  ),
})

export const Home: React.FunctionComponent<IHome> = ({ defaultQuery }) => (
  <div className="flex-stretch flex-container content-container">
    <div className="flex-fixed">
      <div>
        <Label>
          Search for user{' '}
          <TooltipHost tooltipProps={helpText('search for')} closeDelay={1500}>
            <Icon iconName="Info" className={helpIconClass} />
          </TooltipHost>
        </Label>
        <EntityPicker
          selectionRole="search"
          itemLimit={1}
          defaultQuery={defaultQuery}
        />
      </div>
      <div>
        <Label>
          Replace with user{' '}
          <TooltipHost
            tooltipProps={helpText('replace with')}
            closeDelay={1500}
          >
            <Icon iconName="Info" className={helpIconClass} />
          </TooltipHost>
        </Label>
        <EntityPicker selectionRole="replace" itemLimit={1} />
      </div>
    </div>
    <MatchList />
  </div>
)
