import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'

import ReactDOM from 'react-dom'
import { ThemeProvider, initializeIcons } from '@fluentui/react'

import * as Sentry from '@sentry/react'
import { Integrations } from '@sentry/tracing'

import TokenManager from '@xpect-ai/ms-graph-api-token-manager'
import { isPopupWindowWithParent } from '@xpect-ai/ms-office-utils'

import './index.css'

import App from './app/App'

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  environment: process.env.REACT_APP_SENTRY_ENVIRONMENT,
  integrations: [new Integrations.BrowserTracing()],
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
  release: 'entitzer@0.1.0',
  /** Currently the user field is empty and we did not find sensitive data in error logs so far. In case we
   * detect information that we need to filter on client side, the can be removed using the event and hint in
   * beforeSend
   */
  // beforeSend: (event, hint) => {
  //   const sanitizedEvent = event
  //   if (sanitizedEvent.user) {
  //     delete sanitizedEvent.user
  //   }
  //   return event
  // },
})

if (!window.Promise) {
  // @ts-ignore
  window.Promise = Office.Promise
}

initializeIcons()

const title = 'Personal Data Search and Replace'

const render = (Component: any) => {
  ReactDOM.render(
    <ThemeProvider>
      <Component title={title} />
    </ThemeProvider>,
    document.getElementById('root')
  )
}

/* Render application after Office initializes */
/* On slow network connection Office.onReady can trigger before script is loaded. Therefore timeout is needed. */
let timeoutId: NodeJS.Timeout | null = null
Promise.race([
  Office.onReady(),
  new Promise((resolve) => {
    timeoutId = setTimeout(() => {
      resolve(null)
    }, 5000)
  }),
])
  .then(() => {
    const scopes = process.env.REACT_APP_SCOPES
      ? process.env.REACT_APP_SCOPES.replace(/[",]/g, '').split(' ')
      : []

    TokenManager.getInstance(
      process.env.REACT_APP_CLIENT_ID,
      process.env.REACT_APP_REDIRECT_URI,
      scopes
    ).handleInteractiveAuthenticationByPopup()

    if (!isPopupWindowWithParent()) {
      render(App)
    }
  })
  .finally(() => {
    if (timeoutId) clearTimeout(timeoutId)
  })
