import React from 'react'
import * as ReactDOM from 'react-dom'
import ReactTestUtils from 'react-dom/test-utils'
import {
  IPersonaProps,
  NormalPeoplePicker,
  IBasePickerSuggestionsProps,
  ValidationState,
  MessageBarType,
} from '@fluentui/react'
import { useDispatch, useSelector } from 'react-redux'

import getEntities from './searchEntities'
import { IEntity } from '../interfaces/interfaces'
import * as Actions from './selectionActions'
import * as MsgBarActions from '../msgBars/msgBarActions'
import { getMostRecentlyUsed, getSelection } from './selectionSelectors'

interface IPersonaEntityProps extends IPersonaProps {
  entity: IEntity
}

function listContainsPersona(
  persona: IPersonaProps,
  personas: IPersonaProps[]
) {
  return personas.filter((item) => item.text === persona.text).length > 0
}

function removeDuplicates(
  personas: IPersonaProps[],
  possibleDupes: IPersonaProps[]
) {
  return personas.filter(
    (persona) => !listContainsPersona(persona, possibleDupes)
  )
}

function getTextFromItem(persona: IPersonaProps): string {
  return persona.text as string
}

function validateInput(input: string): ValidationState {
  if (input.indexOf('@') !== -1) {
    return ValidationState.valid
  }

  if (input.length > 1) {
    return ValidationState.warning
  }

  return ValidationState.invalid
}

function onInputChange(input: string): string {
  const outlookRegEx = /<.*>/g
  const emailAddress = outlookRegEx.exec(input)

  if (emailAddress && emailAddress[0]) {
    return emailAddress[0].substring(1, emailAddress[0].length - 1)
  }

  return input
}

function getProperty(entity: IEntity, name: string): string {
  const property = entity.properties.find((p) => p.name === name)
  return property ? property.value : ''
}

function toIPersonaProps(entity: IEntity): IPersonaEntityProps {
  return (
    entity && {
      text: getProperty(entity, 'displayName'),
      secondaryText: getProperty(entity, 'jobTitle'),
      tertiaryText: getProperty(entity, 'department'),
      imageUrl: getProperty(entity, 'photo'),
      imageInitials:
        getProperty(entity, 'givenName').substring(0, 1) +
        getProperty(entity, 'surname').substring(0, 1),
      entity,
    }
  )
}

const suggestionProps: IBasePickerSuggestionsProps = {
  suggestionsHeaderText: 'Suggested users',
  mostRecentlyUsedHeaderText: 'Recently used',
  noResultsFoundText: 'No such user found',
  loadingText: 'Searching user directory',
  showRemoveButtons: true,
  suggestionsAvailableAlertText: 'User Picker suggestions available',
  suggestionsContainerAriaLabel: 'Suggested users',
}

interface IEntityLookupProps {
  disabled?: boolean
  resolveDelay?: number
  selectionRole?: string
  itemLimit?: number
  defaultQuery?: string
}

const EntityPicker: React.FunctionComponent<IEntityLookupProps> = ({
  disabled = false,
  resolveDelay = 500,
  selectionRole = 'default',
  itemLimit = undefined,
  defaultQuery,
}) => {
  const mostRecentlyUsed = useSelector(getMostRecentlyUsed).map(toIPersonaProps)
  const currentSelection = useSelector(getSelection(selectionRole)).map(
    toIPersonaProps
  )
  const picker = React.useRef(null)

  React.useEffect(() => {
    if (defaultQuery) {
      const dom = ReactDOM.findDOMNode(picker.current) as Element
      const input = dom.getElementsByTagName('input')[0]
      input.focus()
      input.value = defaultQuery
      ReactTestUtils.Simulate.input(input)
      // TODO (ms): Do we need this call (taken from Jonathan's test class), too?
      // ReactTestUtils.Simulate.change(input)
    }
  }, [defaultQuery])

  const dispatch = useDispatch()

  const onFilterChanged = (
    filterText: string,
    currentPersonas?: IPersonaProps[],
    limitResults?: number
  ): IPersonaProps[] | Promise<IPersonaProps[]> => {
    if (filterText) {
      return getEntities(filterText)
        .then((entities) => {
          return removeDuplicates(
            entities.map(toIPersonaProps),
            currentPersonas || []
          ).slice(0, limitResults)
        })
        .catch((ex) => {
          dispatch({
            type: MsgBarActions.SHOW_MSG_BAR,
            msgBar: {
              type: MessageBarType.error,
              timeout: 5000,
              content: <span>Unable to search directory: {ex.message}</span>,
              actions: undefined,
              hasDismissBtn: true,
              isMultiline: true,
            },
          })
          return []
        })
    }

    return []
  }

  const returnMostRecentlyUsed = (
    currentPersonas?: IPersonaProps[]
  ): IPersonaProps[] | Promise<IPersonaProps[]> => {
    return removeDuplicates(mostRecentlyUsed, currentPersonas || [])
  }

  const onRemoveSuggestion = (item: IPersonaProps): void => {
    dispatch({
      type: Actions.REMOVE_FROM_MRU,
      entity: (item as IPersonaEntityProps).entity,
    })
  }

  const onChange = (items: IPersonaProps[] | undefined): void => {
    const selection = (items || [])
      .filter((i: any) => i.entity)
      .map((i: any) => i.entity)
    dispatch({
      type: Actions.SET_ENTITY_SELECTION,
      role: selectionRole,
      selection,
    })
  }

  return (
    <NormalPeoplePicker
      itemLimit={itemLimit}
      // eslint-disable-next-line react/jsx-no-bind
      onResolveSuggestions={onFilterChanged}
      // eslint-disable-next-line react/jsx-no-bind
      onEmptyInputFocus={returnMostRecentlyUsed}
      getTextFromItem={getTextFromItem}
      pickerSuggestionsProps={suggestionProps}
      className={'ms-PeoplePicker'}
      key={'normal'}
      // eslint-disable-next-line react/jsx-no-bind
      onRemoveSuggestion={onRemoveSuggestion}
      onValidateInput={validateInput}
      selectionAriaLabel={'Selected contacts'}
      removeButtonAriaLabel={'Remove'}
      defaultSelectedItems={currentSelection}
      onInputChange={onInputChange}
      onChange={onChange}
      resolveDelay={resolveDelay}
      disabled={disabled}
      ref={picker}
    />
  )
}

export default EntityPicker
