import React from 'react'
import {
  DefaultButton,
  IStackTokens,
  MessageBar,
  MessageBarType,
  Stack,
  Text,
} from '@fluentui/react'

interface IUserErrorDisplayProps {
  error?: Error
}

const stackProperties: IStackTokens = { childrenGap: 20 }

export const UserErrorDisplay: React.FunctionComponent<
  IUserErrorDisplayProps
> = ({ error }: IUserErrorDisplayProps) => (
  <div className="content-container">
    <h2>Unexpected Error</h2>
    <Stack tokens={stackProperties}>
      <Text>I'm sorry, but an unexpected error has occured.</Text>
      {error && (
        <MessageBar messageBarType={MessageBarType.error}>
          {error.message}
        </MessageBar>
      )}
      <Text>
        I'm afraid we have to restart the add-in. Please click below to do so.
      </Text>
      <DefaultButton text="Reload" onClick={() => window.location.reload()} />
    </Stack>
  </div>
)
