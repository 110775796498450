import SearchResults from './SearchResults'
import SearchResultsGroup from './SearchResultsGroup'

import { IEntity } from '../interfaces/interfaces'

/**
 * Searches for a text pattern in the current document.
 *
 * @param pattern the text pattern to search for
 * @param clientObj if supplied, the search uses the Office RequextContext
 *  of this object
 */
export function searchForPattern(
  pattern: string,
  propertyName: string,
  clientObj?: OfficeExtension.ClientObject
): Promise<SearchResults> {
  return SearchResults.newInstance(pattern, propertyName, clientObj)
}

function getCurrentWordDocument(): Promise<Word.Document> {
  return Word.run((context: Word.RequestContext) => {
    return context.sync(context.document)
  })
}

/**
 * Searches for properties of the supplied entity (name, email, etc.) in
 * the current document.
 *
 * @returns combined search Matches in order of their occurance in the document. Matches
 *  are tagged with their corresponding entity property.
 */
export async function searchForEntityData(
  entity: IEntity
): Promise<SearchResultsGroup> {
  // we use this to get hold of a Word request context for our searches below
  const document = await getCurrentWordDocument()

  return Promise.all(
    entity.properties
      .filter((p) => p.value?.length >= 2 && p.value?.length < 100)
      .map((p) => {
        console.debug('Searching for pattern', p.name, p.value)
        return searchForPattern(String(p.value), p.name, document).then(
          (results) => {
            console.debug('Results for', p.name, ':', results)
            results.tags.add(p.name)
            return results
          }
        )
      })
  ).then((matches) => SearchResultsGroup.newInstance(matches))
}
