import ReactDOM from 'react-dom'

const measureElement = (element: JSX.Element, parentWidth: number) => {
  // Creates the hidden div appended to the document body
  const container = document.createElement('div')
  container.style.display = 'inline-block'
  container.style.position = 'absolute'
  container.style.visibility = 'hidden'
  container.style.zIndex = '-1'
  container.style.width = `${parentWidth}px`
  document.body.appendChild(container)

  // Renders the React element into the hidden div
  ReactDOM.render(element, container)

  // Gets the element size
  const height = container.clientHeight
  const width = container.clientWidth

  // Removes the element and its wrapper from the document
  ReactDOM.unmountComponentAtNode(container)
  container.parentNode?.removeChild(container)
  return { height, width }
}

export default measureElement
